<script setup lang="ts">
import type { Entry_Resources_Resource } from '~~/graphql/graphql'
import type { PropType } from 'vue'
import { useSiteStore } from '~~/stores/site'
import { useUserStore } from '~~/stores/user'
import IconText from '~/assets/svgs/article.svg'
import IconAudio from '~/assets/svgs/headphones.svg'
import IconPlay from '~/assets/svgs/play_circle.svg'

const props = defineProps({
  entry: {
    type: Object as PropType<Entry_Resources_Resource>,
    required: true,
  },
  blankImageHeight: {
    type: Number,
    default: 0,
  },
  blankImageWidth: {
    type: Number,
    default: 0,
  },
  isHover: {
    type: Boolean,
    default: false,
  },
  isSearch: {
    type: Boolean,
    default: false,
  },
  isLink: {
    type: Boolean,
    default: true,
  },
  isDossier: {
    type: Boolean,
    default: false,
  },
  classNames: {
    type: String,
    default: '',
  },
  headingLevel: {
    type: Number,
    default: 2,
  },
  recommendationId: {
    type: String,
    default: '',
  },
})
const card = ref(null)
const baseStorageUrl = useAppConfig().storage.url
const storageBucket = useAppConfig().storage.bucket
const { limitStrLength } = useUtils()
const { languageNames } = useLanguage()

const siteStore = useSiteStore()
const userStore = useUserStore()
const { readingList } = storeToRefs(userStore)

const componentType = computed(() => {
  if (!props.isLink)
    return 'div'

  return resolveComponent('NuxtLink')
})

function setSize() {}

// return icon based on content entry.type with a computed variable
const typeIcon = computed(() => {
  switch (props.entry.data_type) {
    case 'Text':
      return IconText
    case 'Audio':
      return IconAudio
    case 'Video':
      return IconPlay
    default:
      return IconText
  }
})

// merge the results of entry.audio_category , entry.video_category and entry.text_category
const category = computed(() => {
  if (props.entry.data_type === 'Audio')
    return props.entry.audio_category
  else if (props.entry.data_type === 'Video')
    return props.entry.video_category
  else if (props.entry.data_type === 'Text')
    return props.entry.text_category
  else return ''
})
</script>

<template>
  <div ref="card" :class="classNames" @click="userStore.setDetailViewed(entry.id, props.recommendationId || undefined)">
    <component
      :is="componentType"
      :to="`/portal/${entry.slug}`"
      class="group relative flex max-w-sm flex-col rounded-[12px] bg-gray-50 transition-all duration-300 hover:drop-shadow-card hover:drop-shadow-cardLayer"
      :class="[
        {
          'cursor-pointer': !props.isLink,
        },
      ]"
    >
      <div
        class="relative overflow-hidden rounded-t-[12px] bg-digital/30 transition-all duration-300 group-hover:bg-transparent"
        :class="
          {
            'aspect-[276/160] md:aspect-[4/3]': !props.isDossier,
            'aspect-[16/9]': props.isDossier,
          }
        "
      >
        <div
          class="absolute left-1/2 top-1/2 z-20 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white p-[14px] text-digital transition-all duration-300 group-hover:opacity-0 lg:opacity-100"
          :class="[

            {
              'opacity-0': props.isHover,
            },
          ]"
        >
          <component :is="typeIcon" class="size-6" />
        </div>
        <EmbedThumbnail
          v-if="entry.resource_url"
          :embed="entry.resource_url"
          class-names="rounded-t-[12px] blur-sm transition-all duration-300 grayscale group-hover:grayscale-0 group-hover:blur-none mix-blend-multiply"
          aspect-ratio="aspect-[276/160] md:aspect-[4/3]"
          @loaded="setSize()"
        />
        <div v-else-if="entry.card_image">
          <NuxtImg
            :src="`${baseStorageUrl}/${storageBucket}/${entry.card_image[0].path}`"
            :alt="entry.card_image[0].caption"
            :width="entry.card_image[0].width"
            :height="entry.card_image[0].height"
            sizes="sm:400px lg:100vw lg:720px xl:720px"
            class="rounded-t-[12px] mix-blend-multiply blur-sm grayscale transition-all duration-300 group-hover:blur-none group-hover:grayscale-0"
            fit="cover"
            format="avif"
            placeholder
          />
        </div>
        <div
          v-else
          role="status"
          class="space-y-8 lg:flex lg:items-center lg:space-x-8 lg:space-y-0"
        >
          <div
            class="flex aspect-[276/160] size-full items-center justify-center rounded bg-gray-300 sm:w-96 md:aspect-[4/3] dark:bg-gray-700"
          >
            <svg
              class="size-12 text-gray-200"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 640 512"
            >
              <path
                d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="z-10 flex flex-col gap-3 rounded-b-[12px] p-5 md:gap-4 md:py-6"
      >
        <h3
          :aria-level="props.headingLevel ? props.headingLevel : null"
          class="text-boxTitle font-normal"
        >
          {{ entry.title }}
        </h3>
        <div
          class="flex items-center gap-3 md:gap-4"
        >
          <component :is="typeIcon" class="size-6" />
          <div v-if="entry.file_length" class="text-sm">
            {{ entry.file_length }}
          </div>
          <template v-if="entry.languages">
            {{ entry.languages ? '&nbsp;-&nbsp;' : '' }}
            <div class="font-bold">
              {{ entry.languages }}
            </div>
          </template>
          <ClientOnly>
            <template v-if="siteStore.featureFlags.toggle_reading_list">
              <Icon
                v-if="userStore.isInReadingList(entry.id)"
                name="fluent:checkmark-12-regular"
                class="ml-auto size-6 text-black hover:text-primary-200"
                @click.prevent="userStore.removeEntryfromReadingList(entry.id)"
              />
              <Icon
                v-else
                name="fluent:reading-list-add-20-regular"
                class="ml-auto size-6 text-black hover:text-primary-200"
                @click.prevent="userStore.saveEntrytoReadingList(entry.id)"
              />
            </template>
          </ClientOnly>
        </div>
        <div
          v-if="category && category.title"
          class="flex text-smallText text-dark/50"
        >
          {{ category.title }}
        </div>

        <div
          v-if="entry.summary && entry.summary.length > 0 && entry.summary[0].content && entry.summary[0].content.length > 0"
          class="block w-full hyphens-auto text-smallText"
        >
          {{ limitStrLength(entry.summary[0].content[0].text, 150) }}
        </div>
      </div>
    </component>
  </div>
</template>
